import React, { useEffect } from "react";
import "./App.css";

function App() {
  useEffect(() => {
    // Redirect to the specified URL
    window.location.href = "https://cryptoverse.biz";
  }, []); // Empty dependency array to ensure the effect runs only once when the component mounts

  return <div className="App"></div>;
}

export default App;
